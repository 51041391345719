import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
display: flex;
align-items: center;
flex-direction: column;
`

const Description = styled.div`
font-size: 1.0rem;
padding: 5px;

display: flex;
flex-direction: column;

${({ theme }) => theme.media.phone}{
        font-size: 0.7rem;
    }

    ${({ theme }) => theme.media.tablet}{
        font-size: 0.7em;
    }
`


const InputWrapper = styled.div`
    display: flex;
    align-items: center;    
    flex-direction: column;    

    margin: 5px;
    padding: 5px; 
    font-size: 1.1rem;
`

const InputWithLabel = styled.div`
    flex-direction: column;
    display: flex;
    margin: 10px;
    
`

const Label = styled.label`
align-self: flex-start;
    ${({ theme }) => theme.media.tablet}{
        font-size: 0.7em;
    }
    ${({ theme }) => theme.media.phone}{
        font-size: 0.7em;
    }
`

const Input = styled.input`
    
    margin: 5px;
    padding: 5px;
    height: 40px;
    width: 300px;    
    background-color: white;
    font-family: 'Krona One';    
    font-size: 0.7rem;
    ${({ theme }) => theme.media.phone}{
            width: 300px;
    }

    ::placeholder{
        color: rgba(0,0,0,0.5);
        font-size: 0.6rem;
    }
`
const Button = styled.input`
    padding: 5px;
    margin: 5px;
    align-self: flex-end;
    width: 200px;
    border-radius: 10px;
    border-color: white;
    font-family: 'Krona One';
    color: rgba(0,0,0,0.8);
    background-color: white;

    ${({ theme }) => theme.media.phone}{
        align-self: center;
        width: 200px;        
        height: 50px;
    }

`

const H4 =styled.h4`
align-self: center;
font-size: 1.4rem;

${({ theme }) => theme.media.phone}{
            margin-top:30px;
            text-align: center;
            font-size: 0.9rem;
    }

    ${({ theme }) => theme.media.tablet}{
        font-size: 1.1em;
    }
`

const Li = styled.li`
    padding: 10px;
`

class MailChimpForm extends React.Component {
    constructor() {
        super()

        this.state = {
            name: '',
            mail: '',
            city: '',
            submited: false
        }
        
        this.handleName = this.handleName.bind(this);
        this.handleCity = this.handleCity.bind(this);
        this.handleMail = this.handleMail.bind(this);
    }

    handleName(e) {
        this.setState({ name: e.target.value });
    }

    handleMail(e) {
        this.setState({ mail: e.target.value });
    }

    handleCity(e) {
        this.setState({ city: e.target.value });
    }

    render() {
        return (
            <Wrapper>
                <Description>
                    <h2>Rośniemy razem!</h2>

                    <div>Portale społecznościowe przychodzą i odchodzą. Nie sądzisz, że muzyka zasługuje na coś trwalszego?</div>
                    <H4>Zostaw swój e-mail i będziemy  w kontakcie!</H4>
                    <ul>
                        <Li>Napisze tylko wtedy kiedy będzie się dziać coś ciekawego</Li>
                        <Li>Imię uchroni nas przed wylądowaniem w spamie</Li>
                        <Li>Miasto przyda się żeby dać znać o koncercie</Li>
                        <Li>Imię i miasto są opcjonalne</Li>
                    </ul>
                </Description>
                <div>
                    <form  action="https://gmail.us2.list-manage.com/subscribe/post?u=bd3966a14fe71cc17a100ae88&amp;id=3f27d3e6e8" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
                        <InputWrapper id="mc_embed_signup_scroll">
                            <InputWithLabel>
                                {/* <Label >Imie </Label> */}
                                <Input type="text" placeholder="Imię" onChange={this.handleName} value={this.state.name} name="FNAME" className="" id="mce-FNAME" />
                            </InputWithLabel>
                            <InputWithLabel>
                                {/* <Label >Adres e-mail</Label> */}
                                <Input type="email" placeholder="E-mail" onChange={this.handleMail} value={this.state.mail} name="EMAIL" className="required email" id="mce-EMAIL" required />
                            </InputWithLabel>
                            <InputWithLabel>
                                {/* <Label >Miasto </Label> */}
                                <Input type="text" placeholder="Miasto" onChange={this.handleCity} value={this.state.city} name="CITY" className="" id="mce-CITY" />
                            </InputWithLabel>
                            <div id="mce-responses" className="clear">
                                <div className="response" id="mce-error-response" ></div>
                                <div className="response" id="mce-success-response" ></div>
                            </div>

                            <div className="clear"><Button type="submit" value="Leć gołębiu!" name="subscribe" id="mc-embedded-subscribe" className="button" /></div>
                        </InputWrapper>
                    </form>
                </div>
            </Wrapper>
        )
    }
}

export default MailChimpForm;
